export const FEATURE_POWERED_BY = "display_powered_by"
export const FEATURE_OWN_JOBS = "display_own_jobs"
export const FEATURE_OTHER_JOBS = "display_other_jobs"
export const FEATURE_EXTERNAL_APPLICATION = "external_application"
export const FEATURE_GOOGLE_JOBS = "google_jobs"
export const FEATURE_TEASER = "advanced_job"
export const FEATURE_TITLE_IMAGE = "display_title_image"
export const FEATURE_IFRAME = "iframe_enabled"
export const FEATURE_THEME = "theme"
export const FEATURE_JOB_CARD_IMAGE = "display_job_card_image"
