export default {
    data: () => ({
        listeners: [],
    }),
    methods: {
        /** Callback function for event listeners.
         * @callback executorCallback
         */
        /** Adds event listeners to the elements on the events with evenNames
         * @param {(Object|Object[])} elements - elements to which listeners are attached
         * @param {string[]} events - names of tracked events separated by space symbol
         * @param {executorCallback} executor - callback function for event listeners
         * @param {Object} [options=null] - options for event listeners
         * */
        addEventListeners(elements, events, executor, options = null) {
            elements = Array.isArray(elements) ? elements : [elements]
            elements.forEach((element) => {
                events.forEach((event) => {
                    this.listeners.push({
                        element: element,
                        event: event,
                        executor: executor,
                        options: options,
                    })
                    element.addEventListener(event, executor, options)
                })
            })
        },
        /** Removes all event listeners for given elements and events
         * @param {(Object|Object[])} elements - elements for which to remove event listeners
         * @param {string[]} [events=[]] - events for which to remove event listeners (if set to [], then removes all the events)
         * */
        removeEventListeners(elements, events = []) {
            if (this.listeners.length === 0) return
            elements = Array.isArray(elements) ? elements : [elements]
            this.listeners = this.listeners.filter(
                ({ element, event, executor, options }) => {
                    if (
                        elements.includes(element) &&
                        (events.includes(event) || events.length === 0)
                    ) {
                        element.removeEventListener(event, executor, options)
                        return false
                    }
                    return true
                }
            )
        },
    },
    beforeDestroy() {
        for (const { element, event, executor, options } of this.listeners)
            element.removeEventListener(event, executor, options)
        this.listeners = []
    },
}
