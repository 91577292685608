
import JobCard from "@/components/job/card/JobCard"
import entityList from "@/mixins/entityList"

export default {
    name: "JobList",
    components: {
        ListWrapper: () => import("@/components/lists/ListWrapper"),
        JobCard,
        SwiperWrapper: () => import("@/components/swiper/SwiperWrapper"),
        ReplicateJobDialog: () => import("@/components/job/ReplicateJobDialog"),
        ShowMoreCard: () => import("@/components/base/card/ShowMoreCard"),
        VirtualScrollGrid: () =>
            import("@/components/base/list/VirtualScrollGridLegacy"),
    },
    mixins: [entityList],
    props: {
        targetBlank: {
            type: Boolean,
            default: false,
        },
        virtual: {
            type: Boolean,
            default: true,
        },
        swiperID: {
            type: String,
            default: "na",
        },
        showOnlyPublished: {
            type: Boolean,
            default: true,
        },
        wrap: {
            type: Boolean,
            default: false,
        },
        jobType: {
            type: String,
            default: null,
        },
        observe: {
            type: Boolean,
            default: false,
        },
        initialSlide: {
            type: Number,
            default: 0,
        },
        negativeMargin: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            swiper: null,
            observer: null,
            showReplicateDialog: false,
            replicateJobSlug: null,
            replicateBusinessSlug: null,
            canClickForward: true,
            filterPredicate: (item) => {
                if (this.showOnlyPublished) {
                    return item.is_published
                }
                return true
            },
        }
    },
    computed: {
        colsPerRow() {
            if (this.$breakpoint.smAndDown) {
                return 1
            }
            if (this.$breakpoint.md) {
                return 2
            }
            if (this.$breakpoint.lg) {
                return 3
            }
            return 4
        },
        showAlert() {
            return this.items && this.filteredItems.length === 0
        },
    },
    methods: {
        showReplicateJobDialog(data) {
            this.replicateJobSlug = data.jobSlug
            this.replicateBusinessSlug = data.businessSlug
            this.showReplicateDialog = true
        },
    },
}
