// core version + navigation, pagination modules:
import Swiper, { Navigation } from "swiper"
import "swiper/swiper.scss"

// configure Swiper to use modules
export default {
    data: function () {
        return {
            swiper: null,
            canClickForward: true,
            observer: null,
            swiperOptions: {
                slidesPerView: "auto",
                spaceBetween: 20,
                observer: false,
                lazy: false,
                navigation: {
                    nextEl: `.swiper-button-${this.swiperID}-next`,
                    prevEl: `.swiper-button-${this.swiperID}-prev`,
                },
                initialSlide: this.initialSlide,
                modules: [Navigation],
            },
            filterPredicate: (item) => !!item,
        }
    },
    props: {
        wrap: {
            type: Boolean,
            default: false,
        },
        observe: {
            type: Boolean,
            default: false,
        },
        initialSlide: {
            type: Number,
            default: 0,
        },
        items: {
            default: () => [],
            type: Array,
        },
        total: {
            default: 0,
            type: Number,
        },
        limit: {
            default: null,
            type: Number,
        },
        showAllRoute: {
            default: null,
            type: Object,
        },
        showMoreCard: {
            default: true,
            type: Boolean,
        },
        showArrows: {
            default: true,
            type: Boolean,
        },
        showAllText: {
            default: "",
            type: String,
        },
        swiperID: {
            type: String,
            default: () => {
                Math.random().toString(36).substr(2, 9)
            },
        },
    },
    watch: {
        observe: function (newVal) {
            if (newVal === false) {
                this.disableIntersectionObserver()
            } else {
                this.setIntersectionObserver()
            }
        },
        items: function () {
            this.canClickForward = true
        },
    },
    computed: {
        swiperRef() {
            return "swiper_" + this.swiperID
        },
        isLimitReached() {
            return this.limit !== null && this.limit <= this.items.length
        },
        filteredItems() {
            if (!this.items) {
                return []
            }
            const filteredItems = this.items.filter(this.filterPredicate)
            if (this.limit !== null) {
                return filteredItems.slice(0, this.limit)
            }
            return filteredItems
        },
        numberOfHiddenResults() {
            if (this.limit !== null) {
                return this.total - this.filteredItems.length
            }
            return 0
        },
        shouldShowMoreCardBeDisplayed() {
            return (
                this.showMoreCard &&
                this.limit !== null &&
                this.items.length > this.limit
            )
        },
    },
    methods: {
        onListMounted() {
            if (this.$refs[this.swiperRef]?.$refs?.swiper) {
                this.swiper = new Swiper(
                    this.$refs[this.swiperRef].$refs.swiper,
                    this.swiperOptions
                )
                this.swiper.on("tap", () => {
                    this.$store.dispatch("search/saveLastClickedSearchSlide", {
                        index: this.swiper.activeIndex,
                        searchID: this.swiperID,
                    })
                })
            }

            if (this.observe) {
                this.setIntersectionObserver()
            }
        },
        setIntersectionObserver() {
            let selfHandle = this

            if (!this.wrap) {
                if (this.limit === null || this.limit > this.items.length) {
                    this.swiper.on("slideChange", function () {
                        if (this.activeIndex - this.previousIndex > 1) {
                            this.slideTo(this.previousIndex + 1)
                        } else if (
                            selfHandle.observe &&
                            this.progress > 0.8 &&
                            !this.isLimitReached
                        ) {
                            selfHandle.canClickForward = false
                            selfHandle.$emit("intersection")
                        }
                    })
                }
            } else {
                this.observer = new IntersectionObserver(
                    (entries) => {
                        const firstEntry = entries[0]
                        if (firstEntry.isIntersecting) {
                            this.$emit("intersection")
                        }
                    },
                    { rootMargin: "300px" }
                )
                const ref =
                    this.$refs["intersection"] ||
                    this.$refs["scroll-grid"].$refs["intersection"]
                this.observer.observe(ref)
            }
        },

        disableIntersectionObserver() {
            if (this.wrap) {
                this.observer.disconnect()
            }
        },
    },
}
